import axios, { dataOrThrowError } from "@plugins/axios"
import { ProjectRole, ProjectStatus } from "@structs/enums"
import type { PaginatedModels, SortByOrder } from "@structs/interfaces"
import type { Project, User } from "@structs/models"
import { makeSearchParams } from "@utils/array"
import { storeSearchParams } from "@utils/store"
import type { AxiosResponse } from "axios"
import { defineStore } from "pinia"

type storeState = {
  favorites: Project[]
}

export interface ProjectCreateForm {
  name: string
  description?: string
  type: string
  status: string
  city_id: number
  company_id: number
}

export interface ProjectUpdateForm extends ProjectCreateForm {
  id: number
}

export interface SyncUser {
  id: number
  role: string
}

export type ProjectContext = "sidemenu" | "invoices"

export const useProjectStore = defineStore("project", {
  state: () => <storeState>{ favorites: [] },

  getters: {},

  actions: {
    async addUser(projectId: number, userId: number, userRole: ProjectRole) {
      return dataOrThrowError<User>(
        await axios.put(`/projects/${projectId}/users/${userId}`, {
          role: userRole,
        })
      )
    },

    async getFavorites() {
      if (this.favorites?.length > 0) {
        return this.favorites
      }

      const { data, api_error } = await axios.get<Project[] | Error>(
        "/projects/favorites"
      )

      if (api_error) {
        return
      }

      return (this.favorites = data as Project[])
    },

    async addFavorite(modelId: number) {
      return dataOrThrowError<Project>(
        await axios.post(`/projects/${modelId}/favorites`),
        (project) => this.favorites.push(project)
      )
    },

    async deleteFavorite(modelId: number) {
      return dataOrThrowError<Project>(
        await axios.delete(`/projects/${modelId}/favorites`),
        () =>
          this.favorites.splice(
            this.favorites.findIndex((p) => p.id === modelId),
            1
          )
      )
    },

    async create(form?: ProjectCreateForm) {
      return await axios.post<any, AxiosResponse<Project>>("/projects", form)
    },

    async delete(id?: number) {
      const { api_error, data } = await axios.delete<
        any,
        AxiosResponse<string>
      >("/projects/" + id)

      return api_error ? data : true
    },

    async get(id: number): Promise<Project | undefined> {
      return dataOrThrowError<Project>(await axios.get("/projects/" + id))
    },

    async getAll(
      status?: ProjectStatus,
      context?: ProjectContext
    ): Promise<Project[] | undefined> {
      const { data, api_error } = await axios.get<Project[]>(
        "/projects?" + makeSearchParams({ context, status })
      )

      return api_error ? undefined : data
    },

    async getPaginated(page: number, sort: SortByOrder, filters = {}) {
      const { data, api_error } = await axios.get<PaginatedModels<Project>>(
        "/projects?" + storeSearchParams(page, sort, filters)
      )

      return api_error ? undefined : data
    },

    async getUsers(projectId: number) {
      return dataOrThrowError<Project>(
        await axios.get(`/projects/${projectId}/users`)
      )
    },

    async removeUser(projectId: number, userId: number) {
      return dataOrThrowError<Project>(
        await axios.delete(`/projects/${projectId}/users/${userId}`)
      )
    },

    async search(term?: string) {
      const { data, api_error } = await axios.get<Project[]>(
        "/projects/search?term=" + term
      )

      return api_error ? undefined : data
    },

    async setClientAdmin(projectId: number, userId: number) {
      return dataOrThrowError<Project>(
        await axios.put(`/projects/${projectId}/users/${userId}`, {
          role: ProjectRole.client_admin,
        })
      )
    },

    async syncUsers(projectId: number, userIdsWithRole?: SyncUser[]) {
      const { data, api_error } = await axios.post<any, AxiosResponse<Project>>(
        `/projects/${projectId}/users`,
        { users: userIdsWithRole }
      )

      return api_error ? undefined : data
    },

    async update(
      form?: ProjectUpdateForm
    ): Promise<AxiosResponse<Project, any>> {
      const projectData = new FormData()
      Object.keys(form || {}).forEach((field: string) => {
        const value = (form || {})[field as keyof typeof form]
        if (!value) {
          return
        }

        projectData.append(field, value)
      })

      projectData.append("_method", "PUT")
      return await axios.post("/projects/" + form?.id, projectData, {
        headers: { "Content-Type": "multipart/form-data" },
      })
    },
  },
})
