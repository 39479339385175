import { t } from "@plugins/i18n"
import { userIsClient, userIsClientManager } from "@services/user"
import type { User } from "@structs/models"
import type { RouteLocation } from "vue-router"

export default [
  {
    path: "/",
    name: "client.home",
    component: () => import("@client/views/Home.vue"),
    meta: {
      hasAccess: (user: User) => userIsClient(user),
      title: () => t("document_titles.home"),
    },
  },
  {
    path: "/deliverables/:id(\\d+)",
    name: "client.deliverables.show",
    component: () => import("@client/views/DeliverableShow.vue"),
    meta: {
      hasAccess: (user: User) => userIsClient(user),
      title: () => t("document_titles.deliverable_show"),
    },
    props: (route: RouteLocation) => ({
      deliverableId: Number(route.params.id),
      timestamp: Number(route.query.t),
    }),
  },
  {
    path: "/invoices",
    name: "client.invoices.index",
    component: () => import("@client/views/InvoiceIndex.vue"),
    meta: {
      hasAccess: (user: User) => userIsClientManager(user),
      title: () => t("document_titles.invoice_index"),
    },
  },
  {
    path: "/jobs/:id(\\d+)",
    name: "client.jobs.show",
    component: () => import("@client/views/JobShow.vue"),
    meta: {
      hasAccess: (user: User) => userIsClient(user),
      title: () => t("document_titles.job_show"),
    },
    props: (route: RouteLocation) => ({ jobId: Number(route.params.id) }),
  },
  {
    path: "/my-settings",
    name: "client.my_settings",
    component: () => import("@client/views/MySettings.vue"),
    meta: {
      hasAccess: (user: User) => userIsClient(user),
      title: () => t("document_titles.my_settings"),
    },
  },
  {
    path: "/privacy-policy",
    name: "client.privacy_policy",
    component: () => import("@client/views/PrivacyPolicy.vue"),
    meta: {
      hasAccess: (user: User) => userIsClient(user),
      title: () => t("document_titles.privacy_policy"),
    },
  },
  {
    path: "/projects",
    name: "client.projects.index",
    component: () => import("@client/views/ProjectIndex.vue"),
    meta: {
      hasAccess: (user: User) => userIsClient(user),
      title: () => t("document_titles.project_index"),
    },
  },
  {
    path: "/projects/:id(\\d+)",
    name: "client.projects.show",
    component: () => import("@client/views/ProjectShow.vue"),
    meta: {
      hasAccess: (user: User) => userIsClient(user),
      title: () => t("document_titles.project"),
    },
    props: (route: RouteLocation) => ({ projectId: Number(route.params.id) }),
  },
  {
    path: "/proposals",
    name: "client.proposals.index",
    component: () => import("@client/views/ProposalIndex.vue"),
    meta: {
      hasAccess: (user: User) => userIsClient(user),
      title: () => t("document_titles.proposal_index"),
    },
  },
  {
    path: "/proposals/:id(\\d+)",
    name: "client.proposals.show",
    component: () => import("@vh/views/Home.vue"),
    meta: {
      hasAccess: (user: User) => userIsClient(user),
      title: () => t("document_titles.proposal_show"),
    },
    props: (route: RouteLocation) => ({ proposalId: Number(route.params.id) }),
  },
  {
    path: "/proposals/:id(\\d+)/ready",
    name: "client.proposal_ready",
    component: () => import("@client/views/ProposalReady.vue"),
    meta: {
      forceDarkMode: true,
      hasAccess: (user: User) => userIsClient(user),
      title: () => t("document_titles.proposal_ready"),
    },
    props: (route: RouteLocation) => ({ proposalId: Number(route.params.id) }),
  },
  {
    path: "/projects/:id(\\d+)/showroom",
    name: "client.projects.showroom",
    component: () => import("@client/views/Showroom.vue"),
    meta: {
      forceDarkMode: true,
      hasAccess: (user: User) => userIsClient(user),
      title: () => t("document_titles.showroom"),
    },
    props: (route: RouteLocation) => ({ projectId: Number(route.params.id) }),
  },
  {
    path: "/tos",
    name: "client.tos",
    component: () => import("@client/views/TermsOfService.vue"),
    meta: {
      hasAccess: (user: User) => userIsClient(user),
      title: () => t("document_titles.tos"),
    },
  },
].map((r) => ({
  ...r,
  meta: { ...r.meta, forceDarkMode: r.meta.forceDarkMode || false },
}))
