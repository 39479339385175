<script lang="ts">
export default {
  name: "App",
}
</script>

<script setup lang="ts">
import Notification from "@components/Notification.vue"
import { notifications, removeNotification } from "@services/UiNotification"
import router from "@shared/router"
import { computed } from "vue"

const routesWithQuery = ["vh.global_search.index", "vh.deliverables.show"]

const routerKey = computed(() => {
  const currentRoute = router.currentRoute.value
  const routeNameElements = [
    routesWithQuery.indexOf(String(currentRoute.name)) > -1
      ? currentRoute.fullPath
      : currentRoute.path,
  ]

  return routeNameElements.join("|")
})
</script>

<template>
  <div
    v-if="notifications"
    class="fixed top-20 right-8 flex flex-col space-y-2 z-40"
  >
    <Notification
      v-for="notification in notifications"
      :key="notification.id"
      :notification="notification"
      @close="removeNotification(notification.id)"
    />
  </div>

  <router-view :key="routerKey" />
</template>
