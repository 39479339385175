import axios from "@plugins/axios"
import * as Sentry from "@sentry/vue"
import { userIsVh } from "@services/user"
import type {
  LoginCredentials,
  RegisterForm,
  ResetPasswordForm,
} from "@structs/interfaces"
import type { User } from "@structs/models"
import type { AxiosResponse } from "axios"
import { defineStore } from "pinia"
import { useProjectStore } from "./project"

type storeState = {
  authenticated: boolean
  user?: User
}

export const useAuthStore = defineStore("auth", {
  state: () =>
    <storeState>{
      authenticated: false,
      user: undefined,
    },

  actions: {
    async login(form: LoginCredentials) {
      await axios.get("/sanctum/csrf-cookie")

      const data = await axios.post("/login", form)
      if (data.api_error) {
        return data
      }

      const errors = await this.setCurrentUser()
      if (errors) {
        return { data: errors }
      }

      return data
    },

    async logout() {
      await axios.post("/logout")

      await this.loggedOut()
    },

    async register(form: RegisterForm) {
      return await axios.post<User | Error>("/register", form)
    },

    async requestPassword(email: string) {
      return await axios.post("/forgot-password", { email })
    },

    async resetPassword(form: ResetPasswordForm) {
      return await axios.post("/reset-password", form)
    },

    async sendEmailVerification(id: number) {
      return await axios.post(`/verify-email/${id}/resend`)
    },

    async setCurrentUser(user?: User): Promise<Object | undefined> {
      if (user) {
        this.user = user
        return
      }

      try {
        const { data, api_error } = await axios.get<any, AxiosResponse<User>>(
          "/user"
        )

        if (api_error) {
          await this.loggedOut()
          return data
        }

        this.user = data
        this.authenticated = true
        Sentry.setUser({
          id: this.user.id,
          email: this.user.email,
          segment: userIsVh(this.user) ? "visualhouse" : "client",
          username: this.user.name,
        })
      } catch (e) {
        await this.loggedOut()
      }
    },

    async loggedOut() {
      this.$reset()
      Sentry.setUser(null)

      // Reset other stateful stores
      setTimeout(() => useProjectStore().$reset(), 10)
    },
  },
})
