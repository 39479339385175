export interface ArrayByKey<T> {
  [x: string]: T[]
}

export function groupByKey<T>(
  list: T[] | undefined,
  key: string
): ArrayByKey<T> | undefined {
  if (!list) {
    return undefined
  }

  return list.reduce(
    (hash: ArrayByKey<T>, obj) => ({
      ...hash,
      [obj[key as keyof T]]: (hash[obj[key as keyof T]] || []).concat(obj),
    }),
    {}
  )
}

export function moveElement(array: any[], from: number, to: number, count = 1) {
  return (
    (array = array.slice()),
    array.splice(to, 0, ...array.splice(from, count)),
    array
  )
}

export function uniqueValues<T>(arr?: T[]) {
  return Array.from(new Set(arr))
}

export function makeSearchParams(params?: Object) {
  return new URLSearchParams(JSON.parse(JSON.stringify(params)))
}
