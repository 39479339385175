declare module "axios" {
  export interface AxiosResponse<T = any, D = any> {
    data: T
    status: number
    statusText: string
    headers: RawAxiosResponseHeaders | AxiosResponseHeaders
    config: InternalAxiosRequestConfig<D>
    request?: any
    api_error: boolean
  }
}

import { t } from "@plugins/i18n"
import { newNotification, newNotificationOnce } from "@services/UiNotification"
import router from "@shared/router"
import { useAuthStore } from "@stores/auth"
import axios, {
  type AxiosError,
  type AxiosResponse,
  type AxiosResponseTransformer,
} from "axios"

axios.defaults.baseURL = String(import.meta.env.VITE_API_URL)
axios.defaults.withCredentials = true
axios.defaults.withXSRFToken = true
axios.defaults.headers.common["Accept"] = "application/json"
axios.defaults.headers.common["X-Requested-With"] = "XMLHttpRequest"
axios.defaults.validateStatus = (status) => status >= 200 && status < 300
axios.defaults.transformResponse = [
  ...(axios.defaults.transformResponse as AxiosResponseTransformer[]),
  dateTransformer,
]

// axios.interceptors.request.use(
//   (config: AxiosRequestConfig) => ({
//     // Do something before request is sent
//     ...config,
//     metadata: {
//       now: new Date(),
//     },
//   }),
//   // Do something with request error
//   (error: AxiosError) => Promise.reject(error)
// )

// Handle all errors the same way
export interface VhAxiosResponse extends AxiosResponse {
  api_error: boolean
  data: {} | Error
  status: number
}
axios.interceptors.response.use(
  (response: AxiosResponse) => {
    // Keep SPA to latest
    const spaVersion = response.headers["creativehouse-spa-version"]
    if (import.meta.env.VITE_SPA_VERSION !== spaVersion) {
      window.location.reload()
    }

    const { data, status } = response

    return <VhAxiosResponse>{
      api_error: !!(
        typeof data !== "string" && // HTML response
        data.length &&
        "errors" in data &&
        Object.keys(data.errors).length
      ),
      data,
      status,
    }
  },
  async (error: AxiosError) => {
    switch (error.response?.status) {
      case 401:
        if (
          ["/user", "/logout"].indexOf(String(error.response.config.url)) < 0
        ) {
          await useAuthStore().loggedOut()
          router.push({ name: "guest.login" })

          newNotificationOnce(t("users.not_connected"), "error")

          return <VhAxiosResponse>{
            api_error: true,
            data: {},
            status: error.response.status,
          }
        }
        break
      case 403:
        router.back()
        newNotification(t("permissions.not_allowed"), "error")

        break
      default:
    }

    return <VhAxiosResponse>{
      api_error: true,
      data: error.response?.data || { message: error.message },
      status: error.response?.status,
    }
  }
)

export function dataOrThrowError<T>(
  {
    api_error,
    data,
  }: {
    api_error: boolean
    data: T | Error
  },
  successCallback = (data: T) => {}
) {
  if (api_error) {
    throw new Error((data as Error)?.message || "")
  }

  successCallback(data as T)

  return data as T
}

const isoDateRegex =
  /^(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2}(?:\.{0,1}\d*))(?:Z|(\+|-)([\d|:]*))?$/

function dateTransformer(data: any): any {
  if (typeof data === "string") {
    const date = isoDateRegex.exec(data)
    if (!date) {
      return data
    }

    const [iso, year, month, day, hour, minutes, seconds] = date.map((n) =>
      parseInt(n, 10)
    )
    return hour === 0 && minutes === 0 && seconds === 0 // it's a date, not a datetime
      ? new Date(year, month - 1, day) // do NOT apply timezone for dates
      : new Date(data) // apply local timezone for datetimes
  }

  if (Array.isArray(data)) {
    return data.map(dateTransformer)
  }

  if (typeof data === "object" && data !== null) {
    return Object.fromEntries(
      Object.entries(data).map(([key, value]) => [key, dateTransformer(value)])
    )
  }

  return data
}

export default axios
