<script lang="ts">
export default {
  name: "Notification",
}
</script>

<script setup lang="ts">
import Icon from "@components/Icon.vue"
import type {
  UiNotification,
  UiNotificationType,
} from "@services/UiNotification"
import type { paths } from "@services/icon"
import { nextTick, ref } from "vue"

const { notification } = defineProps<{ notification: UiNotification }>()

const emit = defineEmits<{ (e: "close"): void }>()

if (notification.isTimeout) {
  setTimeout(() => close(), 3000)
}

function notificationColors() {
  return {
    success: "bg-emerald-100 text-emerald-700",
    error: "bg-red-100 text-red-700",
    warning: "bg-orange-100 text-orange-700",
  }[notification.type]
}

function notificationIcon(): keyof typeof paths {
  if (notification.icon) {
    return notification.icon
  }

  const typeToIcon = <{ [x in UiNotificationType]: keyof typeof paths }>{
    success: "check_circle",
    error: "error",
    warning: "warning",
  }

  return typeToIcon[notification.type]
}

const closing = ref(false)
function close() {
  closing.value = true
  nextTick(() => emit("close"))
}
</script>

<template>
  <Transition name="slide-left-fade" appear>
    <div
      v-if="!closing"
      class="relative max-w-md p-6 rounded-lg drop-shadow transition-all duration-300 ease-in-out"
      :class="notificationColors()"
    >
      <a
        v-if="!notification.sticky"
        href="#"
        @click.prevent="close()"
        :title="$t('notification.close')"
        class="absolute top-2 right-2"
      >
        <Icon name="x" />
      </a>

      <div class="flex space-x-4">
        <Icon :name="notificationIcon()" class="w-6 h-6" />
        <router-link v-if="notification.route" :to="notification.route">
          <span @click="close()" v-text="notification.message" />
        </router-link>
        <div v-else v-text="notification.message" />
      </div>
    </div>
  </Transition>
</template>

<style>
.slide-left-fade-enter-active,
.slide-left-fade-leave-active {
  @apply transition-all duration-300;
}

.slide-left-fade-enter-from,
.slide-left-fade-leave-to {
  @apply translate-x-full opacity-0;
}
.slide-left-fade-enter-to,
.slide-left-fade-leave-from {
  @apply translate-x-0 opacity-100;
}
</style>
