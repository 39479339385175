export function changePageTitle(title?: Function): void {
  document.title = [title ? title() : null, import.meta.env.VITE_APP_TITLE]
    .filter(Boolean)
    .join(" | ")
}

export function pxToRem(px: number) {
  return px / parseFloat(getComputedStyle(document.documentElement).fontSize)
}

export function toggleDocumentClass(classToToggle: string, toggleOn = true) {
  return document.documentElement.classList.toggle(classToToggle, toggleOn)
}

export function windowHash(): string | undefined {
  return window.location.hash.split("#")[1]
}
