import Echo from "laravel-echo"
import Pusher, { type Options } from "pusher-js"
import axios from "./axios"

const sharedConfig = <Options>{
  authorizer: (channel, options) => {
    return {
      authorize: (socketId, callback: Function) => {
        axios
          .post("/broadcasting/auth", {
            socket_id: socketId,
            channel_name: channel.name,
          })
          .then((response) => callback(null, response.data))
          .catch((error) => callback(error))
      },
    }
  },
  disableStats: true,
  enabledTransports: ["ws", "wss"],
  forceTLS: true,
  httpHost: import.meta.env.VITE_PUSHER_HOST,
  httpsPort: import.meta.env.VITE_PUSHER_PORT || 443,
  wsHost: import.meta.env.VITE_PUSHER_HOST,
  wssPort: import.meta.env.VITE_PUSHER_PORT || 443,
}

export function startEcho() {
  return new Echo({
    broadcaster: "pusher",
    client: new Pusher(import.meta.env.VITE_PUSHER_APP_KEY, sharedConfig),
    encrypted: true,
    key: import.meta.env.VITE_PUSHER_APP_KEY,
    ...sharedConfig,
  })
}
