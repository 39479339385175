export function capitalize(str?: string) {
  return (str && str[0]?.toUpperCase() + str.slice(1)) || ""
}

export function capitalizeWords(str?: string) {
  return str?.replace(/(^\w{1})|([\s\/]+\w{1})/g, (l) => l.toUpperCase())
}

export function excerpt(str: string | null, max: number = 60) {
  if (str === null) {
    return ""
  }

  return str.length < max ? str : str.substring(0, max) + "..."
}

export function hashCode(str: string) {
  if (str.length === 0) {
    return 0
  }

  return [...Array(str.length).keys()].reduce((hash, val, i) => {
    hash = (hash << 5) - hash + str.charCodeAt(i)
    hash |= 0 // Convert to 32bit integer
    return hash
  })
}

export function initials(words?: string) {
  const cleanedWords = String(words)
    .replace(/[^a-zA-Z0-9 ]/g, "")
    .split(" ")

  return cleanedWords.length < 2
    ? cleanedWords[0].substring(0, 2).toLocaleUpperCase()
    : cleanedWords
        .map((word) => word.substring(0, 1))
        .join("")
        .substring(0, 2)
}
