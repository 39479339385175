<script lang="ts">
export default {
  name: "Icon",
}
</script>

<script setup lang="ts">
import { paths } from "@services/icon"
import { computed } from "vue"

const props = withDefaults(
  defineProps<{
    class?: string | Array<string | object>
    name: keyof typeof paths
    outerClass?: string | Array<string | object>
    size?: number
    strokeWidth?: number
  }>(),
  {
    size: 1,
    strokeWidth: 1,
  }
)

const isFill = computed(() => props.name.endsWith("_fill"))

const svgPaths = computed(() => {
  const iconSvgContent = paths[props.name as keyof typeof paths] || ""

  return Array.isArray(iconSvgContent) ? iconSvgContent : [iconSvgContent]
})
</script>

<template>
  <span :class="[props.outerClass, 'icon-' + props.name]">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      :class="props.class || 'w-5 h-5'"
      viewBox="0 0 24 24"
      :width="`${24 * props.size}px`"
      :height="`${24 * props.size}px`"
      :stroke="isFill ? undefined : 'currentColor'"
      :fill="isFill ? 'currentColor' : 'none'"
      @click="$emit('click', $event)"
    >
      <path
        v-for="path in svgPaths"
        :stroke-linecap="isFill ? undefined : 'round'"
        :stroke-linejoin="isFill ? undefined : 'round'"
        :stroke-width="isFill ? undefined : props.strokeWidth"
        :d="path"
        fill-rule="evenodd"
        clip-rule="evenodd"
      />
    </svg>
  </span>
</template>
