import { useAuthStore } from "@stores/auth"
import {
  createRouter,
  createWebHistory,
  type RouteLocationNormalized,
  type RouteLocationRaw,
} from "vue-router"

import "vue-router"
declare module "vue-router" {
  interface RouteMeta {
    forceDarkMode: boolean
    hasAccess: Function
    title?: Function
  }
}

import client from "@client/routes"
import guest from "@guest/routes"
import { resetI18nLanguage } from "@plugins/i18n"
import { loadNotificationsFromStorage } from "@services/UiNotification"
import { userIsClient } from "@services/user"
import { connectUser, disconnectUser } from "@services/websockets"
import { ColorMode, useUiStore } from "@stores/ui"
import type { User } from "@structs/models"
import { hasHistory } from "@utils/history"
import { delay } from "@utils/perf"
import { changePageTitle, toggleDocumentClass } from "@utils/window"
import vh from "@vh/routes"
import type { Channel } from "laravel-echo"

const HOME_ROUTES = {
  guest: "guest.login",
  client: "client.home",
  vh: "vh.home",
}

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [...guest, ...client, ...vh],
})

const guestRoutes = guest.map((r) => r.name)

let intendedRoute: RouteLocationNormalized | undefined = undefined

function noAccessRedirectTo(
  to: RouteLocationNormalized,
  user?: User
): false | string {
  const routeName = String(to.name)

  if (guest.map((r) => r.name).includes(routeName)) {
    return false
  }

  if (!user) {
    intendedRoute = to

    return HOME_ROUTES.guest
  }

  intendedRoute = undefined

  if (userIsClient(user)) {
    return vh.map((r) => r.name).includes(routeName)
      ? HOME_ROUTES.client
      : false
  }

  return false
}

let connectionChannel: Channel | undefined
router.beforeEach(async (to, from, next) => {
  const user = useAuthStore().user
  await resetI18nLanguage()

  const route =
    user && intendedRoute && !guest.map((r) => r.name).includes(String(to.name))
      ? intendedRoute
      : to
  const routeName = String(route.name)

  const redirectTo = noAccessRedirectTo(to, user)
  if (redirectTo !== false) {
    return next({ name: redirectTo })
  }

  if (user && route.meta.hasAccess(user) !== true) {
    return next({
      name: userIsClient(user) ? HOME_ROUTES.client : HOME_ROUTES.vh,
    })
  }

  if (user && routeName === HOME_ROUTES.guest) {
    return next({
      name: userIsClient(user) ? HOME_ROUTES.client : HOME_ROUTES.vh,
    })
  }

  if (!user && !guestRoutes.includes(routeName)) {
    return next({ name: HOME_ROUTES.guest })
  }

  loadNotificationsFromStorage()

  if (user && !connectionChannel) {
    connectionChannel = connectUser()
  } else if (!user && connectionChannel) {
    disconnectUser()
    connectionChannel = undefined
  }

  return user && route.name !== to.name ? next(route) : next()
})

router.afterEach((to, from, failure) => {
  // dynamically change page <title>
  changePageTitle(to.meta.title)

  toggleDocumentClass(
    ColorMode.dark,
    useUiStore().darkMode || to.meta.forceDarkMode
  )
})

router.onError(async (error, to) => {
  // Handle missing chunks,
  // usually cased by an out of date app version
  // as all assets are deleted when a new version is deployed.
  const errors = [
    "Failed to fetch dynamically imported module",
    "Failed to load module script",
    "Unable to preload CSS",
  ]

  // That's why we force a browser reload.
  if (errors.some((e) => error.message.startsWith(e))) {
    await delay(100)

    window.location = to.fullPath
  }
})

export default router

export function backOrHome() {
  if (hasHistory()) {
    return router.back()
  }

  router.push({
    name: userIsClient() ? "client.home" : "vh.home",
  })
}

export function getHash() {
  return router.currentRoute.value.hash.slice(1)
}

export function hasAccess(to: RouteLocationRaw, user?: User): boolean {
  return router.resolve(to).meta.hasAccess(user || useAuthStore().user)
}

export function homeRouteName() {
  const user = useAuthStore().user

  if (!user) {
    return HOME_ROUTES.guest
  }

  return userIsClient(user) ? HOME_ROUTES.client : HOME_ROUTES.vh
}
