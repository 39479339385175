import { i18n, setI18nLanguage } from "@plugins/i18n"
import router from "@shared/router"
import { createApp, ref } from "vue"

import App from "@/App.vue"
import "@shared/css/app.css"
const app = createApp(App)

import { BrowserTracing } from "@sentry/browser"
import * as Sentry from "@sentry/vue"
Sentry.init({
  app,
  dsn: import.meta.env.VITE_SENTRY_DSN,
  integrations: [
    new BrowserTracing({
      routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      tracingOrigins: [import.meta.env.VITE_API_URL],
    }),
  ],
  replaysSessionSampleRate:
    import.meta.env.VITE_SENTRY_REPLAY_SESSION_SAMPLE_RATE || 0.1,
  replaysOnErrorSampleRate:
    import.meta.env.VITE_SENTRY_REPLAY_ERROR_SAMPLE_RATE || 1.0,
  tracesSampleRate: import.meta.env.VITE_SENTRY_SAMPLE_RATE || 1.0,
})

import { createPinia } from "pinia"
const pinia = createPinia()
app.use(pinia)

import { clearNotifications } from "@services/UiNotification"
import { listenToSPAChannel, listenToUserChannel } from "@services/websockets"
import { useAuthStore } from "@stores/auth"
clearNotifications()
ref(useAuthStore())
  .value.setCurrentUser()
  .finally(() => {
    app.use(i18n)
    app.use(router)
    app.mount("#app")

    setI18nLanguage()
    listenToSPAChannel()
    listenToUserChannel()
  })
