import { toggleDocumentClass } from "@utils/window"
import { defineStore } from "pinia"

export enum ColorMode {
  light = "light",
  dark = "dark",
}

type storeState = {
  darkMode: boolean
  projectsMenuOpen: boolean
  sideMenuOpen: boolean
}

export const useUiStore = defineStore("ui", {
  state: () =>
    <storeState>{
      darkMode: localStorage.getItem("colormode") === ColorMode.dark,
      projectsMenuOpen: localStorage.getItem("projectsmenu") === "open",
      sideMenuOpen: localStorage.getItem("sidemenu")
        ? localStorage.getItem("sidemenu") === "open"
        : true,
    },

  getters: {
    colorMode: (state) => (state.darkMode ? ColorMode.dark : ColorMode.light),
  },

  actions: {
    toggleDarkMode() {
      this.darkMode = !this.darkMode
      localStorage.setItem(
        "colormode",
        this.darkMode ? ColorMode.dark : ColorMode.light
      )
      toggleDocumentClass(ColorMode.dark, this.darkMode)
    },

    toggleProjectsMenu() {
      this.projectsMenuOpen = !this.projectsMenuOpen
      localStorage.setItem(
        "projectsmenu",
        this.projectsMenuOpen ? "open" : "close"
      )
    },

    toggleSideMenu() {
      this.sideMenuOpen = !this.sideMenuOpen
      localStorage.setItem("sidemenu", this.sideMenuOpen ? "open" : "close")
    },
  },
})
