import type { SortByOrder } from "@structs/interfaces"

export function storeSearchParams(
  page: number,
  sort: SortByOrder,
  params?: Object
) {
  return new URLSearchParams({
    ...JSON.parse(JSON.stringify(params)),
    sort_by: sort.by || "id",
    sort_order: sort.asc ? "asc" : "desc",
    page,
  }).toString()
}
