import axios from "@plugins/axios"
import { setDayJsLocale } from "@plugins/dayjs"
import { userIsClient } from "@services/user"
import router from "@shared/router"
import { useAuthStore } from "@stores/auth"
import type { User } from "@structs/models"
import { changePageTitle } from "@utils/window"
import { nextTick } from "vue"
import type { Composer, I18nOptions } from "vue-i18n"
import { createI18n } from "vue-i18n"

export const SUPPORT_LOCALES = ["en", "es", "fr"]

// Setup i18n
export const userLocale = localStorage.getItem("locale") || "en"
const options = <I18nOptions>{
  legacy: false,
  locale: userLocale,
  fallbackLocale: userLocale,
  globalInjection: true,
  messages: {},
}
export const i18n = createI18n(options)

export function getLocale(): string {
  return (i18n.global as unknown as Composer).locale.value
}

export async function setI18nLanguage(
  locale?: string,
  user?: User,
  isInitialized: boolean = true
): Promise<void> {
  if (!isInitialized) {
    return
  }

  locale ||= userLocale

  await setDayJsLocale(locale)

  if (!options?.messages || !options?.messages[locale]) {
    nextTick()

    // Use current User if none provided.
    user ||= useAuthStore().user
    const getPortal = (user?: User) => {
      if (!user) {
        return "guest"
      }

      return userIsClient(user) ? "client" : "vh"
    }

    await loadLocaleMessages(locale, getPortal(user))
  }

  ;(i18n.global as unknown as Composer).locale.value = locale
  axios.defaults.headers.common["Accept-Language"] = locale
  document.querySelector("html")!.setAttribute("lang", locale)
  localStorage.setItem("locale", locale)
  changePageTitle(router.currentRoute.value.meta.title)
}

export async function resetI18nLanguage(
  locale?: string,
  user?: User
): Promise<void> {
  options.messages = {}

  return await setI18nLanguage(locale, user)
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const getResourceMessages = (r: any) => r.default || r

export async function loadLocaleMessages(locale: string, portal: string) {
  const messages = await import(`../../${portal}/locales/${locale}.ts`).then(
    getResourceMessages
  )

  i18n.global.setLocaleMessage(locale, messages)

  return nextTick()
}

export function t(key: string, named?: Record<string, unknown>): string {
  return i18n.global.t(key, named)
}

export function te(key: string): boolean {
  return i18n.global.te(key)
}

export function t_unless(key: string, fallback: string): string {
  return i18n.global.te(key) ? t(key) : fallback
}
