import { t } from "@plugins/i18n"
import type { DeliverableStage, Proposal } from "./models"

export enum Currency {
  AED = "AED",
  EUR = "EUR",
  GBP = "GBP",
  HKD = "HKD",
  SAR = "SAR",
  SGD = "SGD",
  USD = "USD",
}

export enum DeliverableStageMediaFileType {
  JPEG = "jpeg",
  JPG = "jpg",
  PDF = "pdf",
  PNG = "png",
}

export enum CompanyType {
  architects = "architects",
  broker = "broker",
  designer = "designer",
  developers = "developers",
  marketing = "marketing",
}

export enum DeliverableStageStatus {
  in_progress = "in_progress",
  approval_requested = "approval_requested",
  edit_requested = "edit_requested",
  approved = "approved",
  on_hold = "on_hold",
  cancelled = "cancelled",
}

export enum DeliverableStageType {
  final_information = "final_information",
  wireframe = "wireframe",
  draft = "draft",
  final_draft = "final_draft",
  final = "final",
}

export enum StageImageSize {
  grid = "grid",
  large = "large",
  full = "full",
}

export enum InvoiceStatus {
  to_review = "to_review",
  sent = "sent",
  paid = "paid",
  overdue = "overdue",
  cancelled = "cancelled",
}

export enum InvoiceType {
  retainer = "retainer",
  final = "final",
  entity_retainer = "entity_retainer",
  entity_final = "entity_final",
}

export enum JobStatus {
  pending = "pending",
  pending_suggested = "pending_suggested",
  accepted = "accepted",
  rejected = "rejected",
  current = "current",
  to_schedule = "to_schedule",
  completed = "completed",
  invoiced = "invoiced",
  on_hold = "on_hold",
  cancelled = "cancelled",
}

export enum ProjectRole {
  client_admin = "client_admin",
  client_billing = "client_billing",
  client_viewer = "client_viewer",
  visualhouse = "visualhouse",
}

export enum ProjectStatus {
  pending = "pending",
  current = "current",
  completed = "completed",
  on_hold = "on_hold",
  cancelled = "cancelled",
}

export enum ProjectType {
  commercial = "commercial",
  residential = "residential",
  hospitality = "hospitality",
  retail = "retail",
}

export enum ProposalStatus {
  in_progress = "in_progress",
  delivered = "delivered",
  approved = "approved",
  active = "active",
  completed = "completed",
  cancelled = "cancelled",
}

export enum ProposalStatusGroup {
  current = "current",
  done = "done",
}

export enum Pronoun {
  he = "he",
  she = "she",
  they = "they",
  not_say = "not_say",
}

export enum Role {
  client = "client",
  client_billing = "client_billing",
  client_admin = "client_admin",
  artist = "artist",
  senior_artist = "senior_artist",
  project_manager = "project_manager",
  director = "director",
  account = "account",
  admin = "admin",
}

/**
 * ENUM FUNCTIONS
 */
export function deadlineTextColor(deadline?: Date): string {
  if (typeof deadline === "undefined") {
    return ""
  }

  const today = new Date().setHours(0, 0, 0, 0)
  const deadlineDay = deadline.setHours(0, 0, 0, 0)

  if (deadlineDay > today) {
    return "text-green-500 dark:text-green-400"
  }

  if (deadlineDay == today) {
    return "text-orange-500 dark:text-orange-400"
  }

  return "text-red-500 dark:text-red-400"
}

export function deliverableStageTypeBgColor(
  type?: DeliverableStageType
): string {
  if (!type) {
    return ""
  }

  return {
    final_information: "bg-gray-100",
    wireframe: "bg-gray-150",
    draft: "bg-blue-300",
    final_draft: "bg-mint-400",
    final: "bg-green-400",
  }[type]
}
export function deliverableStageStatusBgColor(
  status?: DeliverableStageStatus
): string {
  if (!status) {
    return ""
  }

  return {
    approved: "bg-green-200",
    edit_requested: "bg-purple-200",
    approval_requested: "bg-yellow-200",
    in_progress: "bg-gray-150",
    on_hold: "bg-red-300",
    cancelled: "bg-red-200",
  }[status]
}
export function nextDeliverableStageStatus(status?: DeliverableStageStatus) {
  if (!status) {
    return undefined
  }

  return {
    in_progress: DeliverableStageStatus.approval_requested,
    edit_requested: DeliverableStageStatus.approval_requested,
    approval_requested: DeliverableStageStatus.approved,
    approved: undefined,
    on_hold: undefined,
    cancelled: undefined,
  }[status]
}
export function previousDeliverableStageStatus(
  status?: DeliverableStageStatus
) {
  if (!status) {
    return undefined
  }

  return {
    in_progress: undefined,
    edit_requested: DeliverableStageStatus.approval_requested,
    approval_requested: DeliverableStageStatus.in_progress,
    approved: DeliverableStageStatus.approval_requested,
    on_hold: DeliverableStageStatus.in_progress,
    cancelled: DeliverableStageStatus.in_progress,
  }[status]
}
export function deliverableStageName(stage?: DeliverableStage): string {
  if (!stage) {
    return ""
  }

  return t("enums.deliverable_stage_type." + stage.type)
}
export function deliverableStageNameWithNumber(
  stage?: DeliverableStage
): string {
  if (!stage) {
    return ""
  }

  return deliverableStageName(stage) + " " + stage.number
}
export function deliverableStageStatusIsClient(
  status: DeliverableStageStatus
): boolean {
  return ["approval_requested"].includes(status)
}
export function deliverableStageTypeIsClient(
  status: DeliverableStageType
): boolean {
  return ["final_information"].includes(status)
}
export function deliverableStageDeadlineTextColor(
  stage?: DeliverableStage
): string {
  if (!stage || !stage.ends_at) {
    return ""
  }

  return deliverableStageStatusIsClient(stage.status)
    ? "text-blue-600 dark:text-blue-400"
    : deadlineTextColor(stage.ends_at)
}

export function isJobCancelled(status: JobStatus): boolean {
  return JobStatus.cancelled === status
}
export function isJobComplete(status: JobStatus): boolean {
  return [JobStatus.completed, JobStatus.invoiced].includes(status)
}
export function isJobCurrent(status: JobStatus): boolean {
  return [JobStatus.current].includes(status)
}
export function isJobOnHold(status: JobStatus): boolean {
  return [JobStatus.on_hold, JobStatus.to_schedule].includes(status)
}
export function isJobPending(status: JobStatus): boolean {
  return [JobStatus.pending, JobStatus.pending_suggested].includes(status)
}
export function isJobActive(status: JobStatus): boolean {
  return [JobStatus.accepted, JobStatus.current].includes(status)
}
export function jobStatusColor(status: JobStatus): string {
  return {
    pending: "bg-gray-150",
    pending_suggested: "bg-gray-150",
    accepted: "bg-gray-150",
    rejected: "bg-gray-150",
    current: "bg-yellow-200",
    to_schedule: "bg-purple-200",
    completed: "bg-green-200",
    cancelled: "bg-red-200",
    invoiced: "bg-blue-200",
    on_hold: "bg-red-300",
  }[status]
}

export function projectRoleIsClient(role?: ProjectRole): boolean {
  if (!role) {
    return false
  }

  return (
    [
      ProjectRole.client_admin,
      ProjectRole.client_billing,
      ProjectRole.client_viewer,
    ].indexOf(role) > -1
  )
}
export function projectRoleIsClientBilling(role?: ProjectRole): boolean {
  if (!role) {
    return false
  }

  return [ProjectRole.client_admin, ProjectRole.client_billing].includes(role)
}
export function projectRoleIsVisualhouse(role?: ProjectRole): boolean {
  return !projectRoleIsClient(role)
}

export function groupedProposalStatuses() {
  return {
    [ProposalStatusGroup.current]: [
      ProposalStatus.in_progress,
      ProposalStatus.approved,
      ProposalStatus.delivered,
      ProposalStatus.active,
    ],
    [ProposalStatusGroup.done]: [
      ProposalStatus.completed,
      ProposalStatus.cancelled,
    ],
  }
}
export function proposalStatusHasBeenApproved(proposal: Proposal) {
  return [
    ProposalStatus.approved,
    ProposalStatus.active,
    ProposalStatus.completed,
  ].includes(proposal.status)
}
export function proposalStatusBgColor(status?: ProposalStatus): string {
  if (!status) {
    return ""
  }

  return {
    in_progress: "bg-gray-150",
    delivered: "bg-purple-200",
    approved: "bg-blue-200",
    active: "bg-yellow-200",
    completed: "bg-green-200",
    cancelled: "bg-red-200",
  }[status]
}

export function clientUserRoles() {
  return [Role.client, Role.client_admin, Role.client_billing]
}
export function clientUserManagerRoles() {
  return [Role.client_admin, Role.client_billing]
}
export function vhUserRoles() {
  return [
    Role.artist,
    Role.senior_artist,
    Role.project_manager,
    Role.director,
    Role.account,
    Role.admin,
  ]
}
export function vhUserSeniorRoles() {
  return [Role.senior_artist]
}
export function vhUserManagerRoles() {
  return [Role.project_manager, Role.director, Role.admin]
}
export function vhUserBillingRoles() {
  return [Role.account, Role.admin]
}
export function vhUserAdminRoles() {
  return [Role.admin]
}

export function sortEnum<T>(enumsToSort?: T[]) {
  if (!enumsToSort || enumsToSort.length === 0) {
    return []
  }

  const enumIndexes = Object.values(typeof enumsToSort)

  return enumsToSort.sort(
    (a, b) => enumIndexes.indexOf(a) + enumIndexes.indexOf(b)
  )
}

export function activeInvoiceStatuses() {
  return [InvoiceStatus.sent, InvoiceStatus.paid, InvoiceStatus.overdue]
}

export function inactiveInvoiceStatuses() {
  return Object.keys(InvoiceStatus).filter(
    (a) => !Object.keys(activeInvoiceStatuses()).includes(a)
  )
}
