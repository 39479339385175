import { t } from "@plugins/i18n"

export default [
  {
    path: "/forgot-password",
    name: "guest.forgot-password",
    component: () => import("@guest/views/ForgotPassword.vue"),
    meta: {
      title: () => t("document_titles.forgot_password"),
    },
  },
  {
    path: "/login",
    name: "guest.login",
    component: () => import("@guest/views/Login.vue"),
    meta: {
      title: () => t("document_titles.login"),
    },
  },
  {
    path: "/password-reset",
    name: "guest.password-reset",
    component: () => import("@guest/views/PasswordReset.vue"),
    meta: {
      title: () => t("document_titles.password_reset"),
    },
  },
  {
    path: "/register",
    name: "guest.register",
    component: () => import("@guest/views/Register.vue"),
    meta: {
      title: () => t("document_titles.register"),
    },
  },
  {
    // will match everything and put it under `$route.params.pathMatch`
    path: "/:pathMatch(.*)*",
    name: "guest.not-found",
    component: () => import("@views/NotFound.vue"),
    meta: {
      title: () => t("document_titles.not_found"),
    },
  },
].map((r) => ({
  ...r,
  meta: {
    ...r.meta,
    forceDarkMode: true,
    hasAccess: () => true,
  },
}))
