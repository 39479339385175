import { t } from "@plugins/i18n"
import { userIsVhBilling, userIsVhManager } from "@services/user"
import type { User } from "@structs/models"
import type { RouteLocation } from "vue-router"

export default [
  {
    path: "/",
    name: "vh.home",
    component: () => import("@vh/views/Home.vue"),
    meta: {
      hasAccess: (user: User) => true,
      title: () => t("document_titles.home"),
    },
  },
  {
    path: "/accounting-settings",
    name: "vh.accounting_settings",
    component: () => import("@vh/views/AccountingSettings.vue"),
    meta: {
      hasAccess: (user: User) => userIsVhBilling(user),
      title: () => t("document_titles.accounting_settings"),
    },
  },
  {
    path: "/companies/create",
    name: "vh.companies.create",
    component: () => import("@vh/views/CompanyCreate.vue"),
    meta: {
      hasAccess: (user: User) => userIsVhManager(user) || userIsVhBilling(user),
      title: () => t("document_titles.company_create"),
    },
  },
  {
    path: "/companies/:id(\\d+)/edit",
    name: "vh.companies.edit",
    component: () => import("@vh/views/CompanyEdit.vue"),
    meta: {
      hasAccess: (user: User) => userIsVhManager(user) || userIsVhBilling(user),
      title: () => t("document_titles.company_edit"),
    },
    props: (route: RouteLocation) => ({ companyId: Number(route.params.id) }),
  },
  {
    path: "/companies",
    name: "vh.companies.index",
    component: () => import("@vh/views/CompanyIndex.vue"),
    meta: {
      hasAccess: (user: User) => userIsVhManager(user) || userIsVhBilling(user),
      title: () => t("document_titles.company_index"),
    },
  },
  {
    path: "/companies/:id(\\d+)+",
    name: "vh.companies.show",
    component: () => import("@vh/views/CompanyShow.vue"),
    meta: {
      hasAccess: (user: User) => userIsVhManager(user) || userIsVhBilling(user),
      title: () => t("document_titles.home"),
    },
    props: (route: RouteLocation) => ({ companyId: Number(route.params.id) }),
  },
  {
    path: "/deliverables/:id(\\d+)+",
    name: "vh.deliverables.show",
    component: () => import("@vh/views/DeliverableShow.vue"),
    meta: {
      hasAccess: (user: User) => true,
      title: () => t("document_titles.deliverable_show"),
    },
    props: (route: RouteLocation) => ({
      deliverableId: Number(route.params.id),
      timestamp: Number(route.query.t),
    }),
  },
  {
    path: "/invoices",
    name: "vh.invoices.index",
    component: () => import("@vh/views/InvoiceIndex.vue"),
    meta: {
      hasAccess: (user: User) => userIsVhBilling(user),
      title: () => t("document_titles.invoice_index"),
    },
  },
  {
    path: "/invoices/create",
    name: "vh.invoices.create",
    component: () => import("@vh/views/InvoiceCreate.vue"),
    meta: {
      hasAccess: (user: User) => userIsVhBilling(user),
      title: () => t("document_titles.invoice_create"),
    },
    props: (route: RouteLocation) => ({
      proposalId: Number(route.query.proposalId),
    }),
  },
  {
    path: "/jobs/:id(\\d+)+",
    name: "vh.jobs.show",
    component: () => import("@vh/views/JobShow.vue"),
    meta: {
      hasAccess: (user: User) => true,
      title: () => t("document_titles.job_show"),
    },
    props: (route: RouteLocation) => ({ jobId: Number(route.params.id) }),
  },
  {
    path: "/jobs/:id(\\d+)+/stages",
    name: "vh.jobs.stages",
    component: () => import("@vh/views/JobStages.vue"),
    meta: {
      hasAccess: (user: User) => userIsVhManager(user),
      title: () => t("document_titles.job_show"),
    },
    props: (route: RouteLocation) => ({ jobId: Number(route.params.id) }),
  },
  {
    path: "/my-settings",
    name: "vh.my_settings",
    component: () => import("@vh/views/MySettings.vue"),
    meta: {
      hasAccess: (user: User) => true,
      title: () => t("document_titles.my_settings"),
    },
  },
  {
    path: "/projects",
    name: "vh.projects.index",
    component: () => import("@vh/views/ProjectIndex.vue"),
    meta: {
      hasAccess: (user: User) => true,
      title: () => t("document_titles.project_index"),
    },
  },
  {
    path: "/projects/create",
    name: "vh.projects.create",
    component: () => import("@vh/views/ProjectCreate.vue"),
    meta: {
      hasAccess: (user: User) => userIsVhManager(user),
      title: () => t("document_titles.project_create"),
    },
  },
  {
    path: "/projects/:id(\\d+)+",
    name: "vh.projects.show",
    component: () => import("@vh/views/ProjectShow.vue"),
    meta: {
      hasAccess: (user: User) => true,
      title: () => t("document_titles.project_show"),
    },
    props: (route: RouteLocation) => ({ projectId: Number(route.params.id) }),
  },
  {
    path: "/projects/:id(\\d+)/edit",
    name: "vh.projects.edit",
    component: () => import("@vh/views/ProjectEdit.vue"),
    meta: {
      hasAccess: (user: User) => userIsVhManager(user),
      title: () => t("document_titles.project_create"),
    },
    props: (route: RouteLocation) => ({ projectId: Number(route.params.id) }),
  },
  {
    path: "/proposals",
    name: "vh.proposals.index",
    component: () => import("@vh/views/ProposalIndex.vue"),
    meta: {
      hasAccess: (user: User) => userIsVhManager(user) || userIsVhBilling(user),
      title: () => t("document_titles.proposal_index"),
    },
  },
  {
    path: "/proposals/create",
    name: "vh.proposals.create",
    component: () => import("@vh/views/ProposalCreate.vue"),
    meta: {
      hasAccess: (user: User) => userIsVhManager(user),
      title: () => t("document_titles.proposal_create"),
    },
  },
  {
    path: "/proposals/:id(\\d+)/edit",
    name: "vh.proposals.edit",
    component: () => import("@vh/views/ProposalEdit.vue"),
    meta: {
      hasAccess: (user: User) => userIsVhManager(user),
      title: () => t("document_titles.proposal_edit"),
    },
    props: (route: RouteLocation) => ({ proposalId: Number(route.params.id) }),
  },
  {
    path: "/proposals/:id(\\d+)",
    name: "vh.proposals.show",
    component: () => import("@vh/views/ProposalShow.vue"),
    meta: {
      hasAccess: (user: User) => userIsVhManager(user),
      title: () => t("document_titles.proposal_show"),
    },
    props: (route: RouteLocation) => ({ proposalId: Number(route.params.id) }),
  },
  {
    path: "/proposals/:id(\\d+)/build",
    name: "vh.proposals.build",
    component: () => import("@vh/views/ProposalBuild.vue"),
    meta: {
      hasAccess: (user: User) => userIsVhManager(user),
      title: () => t("document_titles.proposal_build"),
    },
    props: (route: RouteLocation) => ({ proposalId: Number(route.params.id) }),
  },
  {
    path: "/search",
    name: "vh.global_search.index",
    component: () => import("@vh/views/GlobalSearchIndex.vue"),
    meta: {
      hasAccess: (user: User) => true,
      title: () => t("document_titles.global_search"),
    },
    props: (route: RouteLocation) => ({
      models: String(route.query.models).split(","),
      term: route.query.term,
    }),
  },
  {
    path: "/users/create",
    name: "vh.users.create",
    component: () => import("@vh/views/UserCreate.vue"),
    meta: {
      hasAccess: (user: User) => userIsVhManager(user),
      title: () => t("document_titles.user_create"),
    },
  },
  {
    path: "/users",
    name: "vh.users.index",
    component: () => import("@vh/views/UserIndex.vue"),
    meta: {
      hasAccess: (user: User) => userIsVhManager(user),
      title: () => t("document_titles.user_index"),
    },
  },
  {
    path: "/users/:id(\\d+)/edit",
    name: "vh.users.edit",
    component: () => import("@vh/views/UserEdit.vue"),
    meta: {
      hasAccess: (user: User) => true,
      title: () => t("document_titles.user_edit"),
    },
    props: (route: RouteLocation) => ({ userId: Number(route.params.id) }),
  },
  {
    path: "/users/:id(\\d+)",
    name: "vh.users.show",
    component: () => import("@vh/views/UserEdit.vue"),
    meta: {
      hasAccess: (user: User) => true,
      title: () => t("document_titles.user_show"),
    },
    props: (route: RouteLocation) => ({ userId: Number(route.params.id) }),
  },
  {
    // will match everything and put it under `$route.params.pathMatch`
    path: "/:pathMatch(.*)*",
    name: "vh.not_found",
    component: () => import("@views/NotFound.vue"),
    meta: {
      hasAccess: (user: User) => true,
      title: () => t("document_titles.not_found"),
    },
  },
].map((r) => ({
  ...r,
  path: "/vh" + r.path,
  meta: { ...r.meta, forceDarkMode: false },
}))
