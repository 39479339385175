import dayjs, { Dayjs } from "dayjs"
import isBetween from "dayjs/plugin/isBetween"
import localizedFormat from "dayjs/plugin/localizedFormat"

dayjs.extend(isBetween)
dayjs.extend(localizedFormat)

const locales = <{ [x: string]: Function }>{
  en: () => import("dayjs/locale/en"),
  es: () => import("dayjs/locale/es"),
  fr: () => import("dayjs/locale/fr"),
}

export async function setDayJsLocale(locale: string) {
  await locales[locale as keyof typeof locales]()
  dayjs.locale(locale)
}

export { type Dayjs }

export default dayjs
