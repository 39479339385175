import { t } from "@plugins/i18n"
import { hashCode } from "@utils/string"
import { ref } from "vue"
import type { RouteLocationRaw } from "vue-router"
import type { paths } from "./icon"

export type UiNotificationType = "success" | "warning" | "error"

export interface UiNotification {
  id: number
  icon?: keyof typeof paths
  isTimeout: boolean
  message: string
  route?: RouteLocationRaw
  sticky: boolean
  type: UiNotificationType
}

const notifications = ref<UiNotification[]>([])

function clearNotifications() {
  notifications.value = []
  setLocalStorage(notifications.value)
}

function loadNotificationsFromStorage() {
  notifications.value = JSON.parse(
    window.localStorage.getItem("notifications") || "[]"
  )
}

function newNotification(
  message: string,
  type: UiNotificationType = "success",
  isTimeout: boolean = true,
  route?: RouteLocationRaw,
  sticky: boolean = false,
  icon?: keyof typeof paths
) {
  notifications.value?.push(<UiNotification>{
    id: hashCode(type + message + Date.now()),
    icon,
    isTimeout: type === "error" ? false : isTimeout,
    message: message || t("notification.default." + type),
    route,
    sticky,
    type,
  })

  setLocalStorage(notifications.value)
}

function newNotificationOnce(
  message: string,
  type: UiNotificationType = "success",
  isTimeout: boolean = true,
  route?: RouteLocationRaw,
  sticky: boolean = false,
  icon?: keyof typeof paths
) {
  if (notifications.value.findIndex((n) => n.message === message) !== -1) {
    return
  }

  newNotification(message, type, isTimeout, route, sticky, icon)
}

function newStickyNotification(
  message: string,
  type: UiNotificationType = "success",
  route?: RouteLocationRaw,
  icon?: keyof typeof paths
) {
  newNotification(message, type, false, route, true, icon)
}

function removeNotification(id: number) {
  if (!notifications.value) {
    return
  }

  const notificationIndex = notifications.value.findIndex((n) => n.id === id)
  if (notificationIndex === -1) {
    return
  }

  notifications.value.splice(notificationIndex, 1)

  setLocalStorage(notifications.value)
}

function setLocalStorage(notifications: UiNotification[]) {
  window.localStorage.setItem("notifications", JSON.stringify(notifications))
}

export {
  clearNotifications,
  loadNotificationsFromStorage,
  newNotification,
  newNotificationOnce,
  newStickyNotification,
  notifications,
  removeNotification,
}
