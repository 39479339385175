import { startEcho } from "@plugins/echo"
import { t } from "@plugins/i18n"
import {
  newNotification,
  newStickyNotification,
} from "@services/UiNotification"
import router from "@shared/router"
import { useAuthStore } from "@stores/auth"
import { deliverableStageName } from "@structs/enums"
import type { DeliverableStage } from "@structs/models"
import { ref } from "vue"

export const echo = startEcho()

export function listenToSPAChannel() {
  return echo
    .channel("spa")
    .listen("SPAWasUpdated", (e: Event) =>
      newStickyNotification(
        t("spa.please_update"),
        "warning",
        undefined,
        "arrow-load"
      )
    )
}

export function listenToUserChannel() {
  const currentUserId = useAuthStore().user?.id
  if (!currentUserId) {
    return
  }

  return echo
    .private("user." + currentUserId)
    .listen(
      "DeliverableStagePdfWasConverted",
      ({ deliverableStage }: { deliverableStage: DeliverableStage }) => {
        const deliverableRoute = {
          name: "vh.deliverables.show",
          params: { id: deliverableStage.deliverable?.id },
        }

        if (
          router.currentRoute.value.path ===
          router.resolve(deliverableRoute).path
        ) {
          router.replace({ ...deliverableRoute, query: { t: Date.now() } })

          return
        }

        newNotification(
          t("deliverables.show.pdf.converted", {
            deliverable_name: deliverableStage.deliverable?.name,
            stage_name: deliverableStageName(deliverableStage),
          }),
          "success",
          false,
          deliverableRoute
        )
      }
    )
}

interface EchoUser {
  id: number
  name: string
}

export const connectedUsers = ref<EchoUser[]>([])
export function connectUser() {
  return echo
    .join("connections")
    .here((users: EchoUser[]) =>
      connectedUsers.value.splice(0, Infinity, ...users)
    )
    .joining((user: EchoUser) => {
      if (connectedUsers.value.findIndex(({ id }) => id === user.id) === -1) {
        connectedUsers.value.push(user)
      }
    })
    .leaving((user: EchoUser) =>
      connectedUsers.value.splice(
        connectedUsers.value.findIndex(({ id }) => id === user.id),
        1
      )
    )
    .error((error: Error) => console.error(error))
}
export function disconnectUser() {
  return echo.leave("connections")
}

// automatically disconnect users when closing tab
window.addEventListener("onbeforeunload", disconnectUser)
